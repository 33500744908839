.hero-text {
	position: absolute;
	display: block;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 30px;
	background-color: rgba(0, 0, 0, 0.5);
	margin: 0;
	h1 , h2, p {
		color: #FFF;
		margin: 0;
	}
}

.hero-title {
	display: block;
	padding: 30px;
	text-align: left;
	color: #FFF;
	background-color: $primary;
	font-size: 2rem;
	@include media-breakpoint-up(md) {
		font-size: 3rem;
	}	
}

.hero-img {
	width: 100%;
	height: 300px;
	@include media-breakpoint-up(md) {
		height: 100%;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.section-hero {
	position: relative;
	.carousel, .carousel-inner, .carousel-item {
		height: 100%;
	}
}

#hero-carousel {
	height: 260px;
	@include media-breakpoint-up(sm) {
		height: 380px;
	}
	@include media-breakpoint-up(md) {
		height: 540px;
	}
	div {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
