// Variables

// Fonts
$font-family-sans-serif:   futura-pt, sans-serif;
$font-weight-bold:         600 !default;

// Color system

$primary: 	#041DB5;
$secondary: #FFB826;
$danger: 	#D11335;
$info: 		#EE7203;
$warning: 	#FDCC20;
$success: 	#A1C958;
$light:		#F3F3F3;
$dark:		#2A3B72;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1440px,
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1280px
);

// Body

$body-bg:		#fff;
$body-color:	$dark;

// Links

$link-decoration:       none;
$link-hover-decoration: none;


// Navs

$navbar-light-color:            $primary;
$navbar-nav-link-padding-x: 	.5rem;
$navbar-padding-y: 				2rem;
$navbar-padding-x: 				0;
$navbar-toggler-padding-y:		0;
$navbar-toggler-padding-x:		0;
$navbar-toggler-border-radius:	0;
$navbar-light-toggler-icon-bg:	url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='40' height='40' /%3E%3Cpath d='M8 28V25.3333H32V28H8ZM8 21.3333V18.6667H32V21.3333H8ZM8 14.6667V12H32V14.6667H8Z' fill='white'/%3E%3C/svg%3E%0A");
// Dropdowns
//
// Dropdown menu container and contents.


// Cards

$card-spacer-y:                     1.25rem;
$card-spacer-x:                     1.25rem;
/* $card-border-width:                 0; */

// Buttons + Forms

$input-btn-padding-y-lg:      .675rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size:         1.125rem;
$input-btn-font-size-lg:      1.5rem;
$input-btn-line-height:       1.5;

// Buttons

$btn-padding-y:     0.375rem;
$btn-padding-x:     1.5rem;
$btn-font-weight:	600;

$btn-border-radius:   999px;
$btn-font-size-lg:    1rem;


// Forms

$label-margin-bottom:		.75rem;
$input-padding-y: 			.5rem;
$input-padding-x: 			1rem;
$input-color:               $primary;
$input-border-color:        #DDDDDD;
$input-group-addon-color:	$primary;
$input-group-addon-bg:  	#DDDDDD;
$form-group-margin-bottom:  0;
$input-font-weight:         400;


// Typography

$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1.125rem;
$h5-font-size: 1rem;

$headings-font-weight:	600;
$headings-color: 		$primary;

$font-weight-bolder:    bold;

$headings-margin-bottom:  1rem;

