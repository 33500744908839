@import "../bootstrap/forms";
@import "../bootstrap/input-group";

.form-control {
	appearance: none;
	@extend .px-3;
	@extend .py-2;
	font-size: 1.125rem;
	&::placeholder {
		@extend .text-secondary;
	}
}

.select2-container {
    width: 100% !important;
}

select.form-control {
	cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' viewBox='0 0 8 16' fill='none'%3E%3Cpath d='M0 -3.49691e-07L-6.99382e-07 16L8 8' fill='%232A3B72'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: .5rem;
    background-position: calc(100% - 1rem) center;
}

.select2-container--default .select2-selection--multiple {
    min-height: 45px;
    border-radius: 4px;
}
.select2-container--default .select2-selection--multiple textarea.select2-search__field {
    min-height: 45px;
    margin: -4px;
    padding: 12px 22px;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: 1.2em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' viewBox='0 0 8 16' fill='none'%3E%3Cpath d='M0 -3.49691e-07L-6.99382e-07 16L8 8' fill='%232A3B72'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: .5rem;
    background-position: calc(100% - .5rem) center;
    &::placeholder {
        color: #041DB5;
    }
}
