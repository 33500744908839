@import "../bootstrap/card";

.card {
	img {
    	@extend .img-fluid;
	}
}

.card-100 {
    position: relative;
    height: 100%;
    .card-title {
        @extend .h6;
        @extend .text-dark;
        @extend .mb-0;
    }
}
