@import "../bootstrap/buttons";


.btn {
    color: $secondary;
    text-transform: uppercase;
}
.btn-secondary {
    color: $primary;
}
.btn-white {
    @extend .bg-white;
}
.btn-outline-primary {
    background-color: white;
    color: $primary;
    text-transform: none;
}

.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
        content: '';
        width: 1em;
        height: 1em;
        margin-right: .5rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-block;
        vertical-align: middle;
    }
}
.btn-icon-right {
    &:before {
        content: unset;
    }
    &:after {
        content: '';
        width: 1em;
        height: 1em;
        margin-left: .5rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-block;
    }
}
.btn-icon-export {
    &:before, &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M0 0L224 0l0 160 160 0 0 128-168 0-24 0 0 48 24 0 168 0 0 176L0 512 0 0zM384 336l0-48 110.1 0-39-39-17-17L472 198.1l17 17 80 80 17 17-17 17-80 80-17 17L438.1 392l17-17 39-39L384 336zm0-208l-128 0L256 0 384 128z' fill='%23041db5'/%3E%3C/svg%3E");
    }
    &.btn-outline-primary:hover {
        &:before, &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M0 0L224 0l0 160 160 0 0 128-168 0-24 0 0 48 24 0 168 0 0 176L0 512 0 0zM384 336l0-48 110.1 0-39-39-17-17L472 198.1l17 17 80 80 17 17-17 17-80 80-17 17L438.1 392l17-17 39-39L384 336zm0-208l-128 0L256 0 384 128z' fill='%23fff'/%3E%3C/svg%3E");
        }
    }
}

.btn-icon-print {
    &:before, &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M96 0L368 0l13.3 0 9.4 9.4 48 48 9.4 9.4L448 80l0 80-64 0 0-66.7L354.7 64 128 64l0 96-64 0L64 32 64 0 96 0zM64 384L0 384 0 192l512 0 0 192-64 0 0 96 0 32-32 0L96 512l-32 0 0-32 0-96zm64 0l0 64 256 0 0-64 0-32-256 0 0 16 0 16zM408 248l0 48 48 0 0-48-48 0z' fill='%23041db5'/%3E%3C/svg%3E");
    }
    &.btn-outline-primary:hover {
        &:before, &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M96 0L368 0l13.3 0 9.4 9.4 48 48 9.4 9.4L448 80l0 80-64 0 0-66.7L354.7 64 128 64l0 96-64 0L64 32 64 0 96 0zM64 384L0 384 0 192l512 0 0 192-64 0 0 96 0 32-32 0L96 512l-32 0 0-32 0-96zm64 0l0 64 256 0 0-64 0-32-256 0 0 16 0 16zM408 248l0 48 48 0 0-48-48 0z' fill='%23fff'/%3E%3C/svg%3E");
        }
    }
}

.btn-icon-arrow {
    &:before, &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12' fill='none'%3E%3Cpath d='M6 6L0 12L-5.24537e-07 0L6 6Z' fill='%232A3B72'/%3E%3C/svg%3E");
    }
}


a.toggle-watchlist-item {
	color: #FFF;
    &:after {
        content: '';
        width: 1em;
        height: 1em;
        margin-left: .5rem;
        margin-top: -3px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-block !important;
        vertical-align: middle !important;
  		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 512 512%22%3E%3Cpath fill=%22%23FFFFFF%22 d=%22M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z%22/%3E%3C/svg%3E');
    }
    /*
	background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 512 512%22%3E%3Cpath fill=%22%23041db5%22 d=%22M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z%22/%3E%3C/svg%3E');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-color: transparent;
	border:0;
	*/
	&.toggle-watchlist-item-on {
    	&:after {
			background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 512 512%22%3E%3Cpath fill=%22%23FFFFFF%22 d=%22M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z%22/%3E%3C/svg%3E');
		}
	}
}
