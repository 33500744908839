@import "common/variables";

/** Import everything from bootstrap */
@import "bootstrap/scss/bootstrap-reboot.scss";
@import "bootstrap/scss/bootstrap-grid.scss";

@import "common/utilities";

@import "includes";

/*
img {
    @extend .img-fluid;
}
*/

.container-content {
	p {
		font-size: 1.3rem;
	}
}

footer {
    background-color: $primary !important;
    text-align: center !important;
    color: white !important;
    padding: 2rem 0 !important;
    @include media-breakpoint-up(md) {

    }
    @include media-breakpoint-up(lg) {
        font-size: 1.125rem !important;
        padding: 4rem 0 !important;
        text-align: left !important;
    }
    ul {
        @extend .list-unstyled;
        @extend .mb-0;
    }
    a {
        color: white !important;
    }
    .row {
        row-gap: 1.5rem !important;
    }
    .footer-iso {
        display: flex !important;
        align-items: start !important;
        justify-content: center !important;
        gap: 1.5rem !important;
        @include media-breakpoint-up(lg) {
            order:-9999 !important;
            justify-content: flex-start !important;
        }
    }
    .footer-copy {
        font-size: .875rem !important;
        @extend .mb-0;
    }
}
.title-container {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 1.25rem !important;
    text-align: center !important;
    @include media-breakpoint-up(lg) {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
        gap: 2.5rem !important;
        text-align: left !important;
    }
    h1, .h1 {
        text-transform: uppercase !important;
        flex-shrink: 0 !important;
        margin-bottom: 0 !important;
    }
    .page-title {
        display: flex !important;
        align-items: center !important;
        gap: 1.5rem !important;
        &:after {
            content: '' !important;
            background-color: $dark !important;
            flex: 1 1 auto !important;
            height: 1px !important;
        }
        &:before {
            content: '' !important;
            background-color: $dark !important;
            flex: 1 1 auto !important;
            height: 1px !important;

            @include media-breakpoint-up(lg) {
                content: unset !important;
            }
        }
    }

}


.filter-search {
    background-color: $primary !important;
    margin: 1.25rem 0 !important;

    .container {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.25rem !important;
    }

    .filter-toggle {
        transform: translateY(-50%) !important;
        display: flex !important;
        justify-content: center !important;

        .btn[aria-expanded="false"] .btn-text-opened {
            display: none !important;
        }
        .btn[aria-expanded="true"] .btn-text-closed {
            display: none !important;
        }
    }

    .filter-count {
        text-align: center !important;
        color:white !important;
        font-size: 1.25rem !important;
    }

    .filter-reset {
        text-align: center !important;
        color:white !important;
        font-size: 1.25rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        &:before {
            content: '' !important;
            width: 1em !important;
            height: 1em !important;
            margin-right: .5rem !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            background-position: center !important;
            display: inline-block !important;
            vertical-align: middle !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='20' viewBox='0 0 18 20' fill='none'%3E%3Cpath d='M9 14C10.671 14 12 12.669 12 11C12 9.331 10.671 8 9 8C7.329 8 6 9.331 6 11C6 12.669 7.329 14 9 14Z' fill='white'/%3E%3Cpath d='M17.817 9.186C17.5824 8.03404 17.1218 6.93998 16.462 5.967C15.8156 5.00844 14.9906 4.1834 14.032 3.537C13.0578 2.87947 11.9642 2.41914 10.813 2.182C10.2081 2.05913 9.59222 1.99882 8.975 2.002V0L5 3L8.975 6V4.002C9.459 4 9.943 4.046 10.41 4.142C11.3047 4.32638 12.1547 4.6841 12.912 5.195C13.6585 5.69815 14.3009 6.34055 14.804 7.087C15.5852 8.24231 16.0018 9.60539 16 11C16.0007 11.936 15.8137 12.8626 15.45 13.725C15.2741 14.1408 15.0585 14.5387 14.806 14.913C14.5541 15.2861 14.2667 15.6341 13.948 15.952C12.9799 16.9181 11.7511 17.5808 10.412 17.859C9.48081 18.0478 8.52119 18.0478 7.59 17.859C6.69486 17.6744 5.84452 17.3164 5.087 16.805C4.34145 16.3022 3.69976 15.6605 3.197 14.915C2.41678 13.7584 1.99995 12.3951 2 11H3.23179e-08C-0.000151954 12.7939 0.535776 14.5469 1.539 16.034C2.18692 16.9901 3.01092 17.8141 3.967 18.462C5.45262 19.4675 7.20611 20.0033 9 20C9.6093 20.0006 10.2171 19.9392 10.814 19.817C11.9648 19.5797 13.0581 19.1194 14.032 18.462C14.5099 18.1392 14.9556 17.7711 15.363 17.363C15.7715 16.9553 16.1399 16.5092 16.463 16.031C17.4677 14.5458 18.0032 12.7931 18 11C18.0006 10.3907 17.9392 9.78291 17.817 9.186Z' fill='white'/%3E%3C/svg%3E") !important;
        }

        @include media-breakpoint-up(lg) {
            text-align: right !important;
            justify-content: end !important;
        }
    }

    .filter-form-fields.collapse.show, .filter-form-fields:not(.collapse) {
        display: grid !important;
    }

    .filter-form-fields {
        grid-template-columns:repeat(1,minmax(0,1fr)) !important;
        gap: 1.5rem !important;
        .form-control {
            border:0 !important;
        }
        @include media-breakpoint-up(sm) {
            grid-template-columns:repeat(2,minmax(0,1fr)) !important;
            /*
            .filter-form-fields, &:not(.collapse) > .form-group:first-of-type {
                grid-column: span 2/span 2 !important;
            }
            */
            .form-group.full-width {
            	grid-column: span 2/span 2 !important;
            }
        }
        @include media-breakpoint-up(lg) {
            gap: 2rem !important;
            grid-template-columns:repeat(5,minmax(0,1fr)) !important;
            .filter-form-fields {
                grid-column: span 5/span 5 !important;
            }
            /*
            &:not(.collapse) .form-group:first-of-type {
                grid-column: span 1/span 1 !important;
            }
            */
            .form-group.full-width {
            	grid-column: span 5/span 5 !important;
            }
        }
    }

    .filter-submit {
        margin-top: 1.25rem !important;
        transform: translateY(50%) !important;
        display: flex !important;
        justify-content: center !important;
    }
}

#carouselBanner {
    background-color: $light !important;
   margin-top: 2rem !important;
    @include media-breakpoint-up(lg) {
       margin-top: 4rem !important;
    }
    .carousel-item {
        background-color: $primary !important;
    }
    .carousel-indicators {
        margin-bottom: 0 !important;
    }
}
.homepage-banner {
    background-color: $primary !important;
    position: relative !important;

    .banner-body {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        color:white !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        text-align: center;

        line-height: 1.1 !important;
        font-size: 1.25rem !important;
        @include media-breakpoint-up(md) {
            font-size: 1.5rem !important;
        }
        @include media-breakpoint-up(lg) {
        	text-align: left;
        }
        @include media-breakpoint-up(xl) {
            font-size: 1.875rem !important;
        }
        p:last-of-type {
            margin-bottom: 0 !important;
        }
    }
    .banner-logo {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        display: flex !important;
        align-items: center !important;
        text-align: center;
        img {
        	margin: auto;
        }
    }
    .banner-image {
        padding: 0;
        text-align: center;
        @include media-breakpoint-up(lg) {
        	align-items: end !important;
        	text-align: right;
        }
        img {
        	display: block;
        	margin: auto;
        	@include media-breakpoint-up(lg) {
        		margin: 0;
        		float: right;
        	}
        }
    }
}

.upcoming-auctions {
    background-color: $light !important;
    padding: 2rem 0 !important;
    @include media-breakpoint-up(lg) {
        padding: 4rem 0 !important;
    }

    //&-primary {
    //    background-color: rgba(4, 29, 181, 0.10) !important;
    //}
    //&-secondary {
    //    background-color: rgba(255, 184, 38, 0.20) !important;
    //}

    h2 {
        margin-bottom: 2rem !important;
        @include media-breakpoint-up(lg) {
            margin-bottom: 4rem !important;
        }
    }

    .auctions-grid {
        display: grid !important;
        gap: 1.5rem !important;
        grid-template-columns:repeat(1,minmax(0,1fr)) !important;

        @include media-breakpoint-up(md) {
            grid-template-columns:repeat(2,minmax(0,2fr)) !important;
        }

        @include media-breakpoint-up(lg) {
            gap: 2rem !important;
            grid-template-columns:repeat(3,minmax(0,3fr)) !important;
        }
    }

    .auction-card {
        font-size: .875rem !important;
        padding: 1.25rem !important;
        a {
            text-decoration: underline !important;
        }
        * {
            margin-bottom: .5rem !important;
        }
        &-primary {
            background-color: $primary !important;
            * {
                color: white !important;
            }
        }
        &-white {
            background-color: white !important;
            * {
                color: $dark !important;
            }
        }
        &-secondary {
            background-color: $secondary !important;
            * {
                color: $dark !important;
            }
        }
    }

    .auctions-cta {
        margin-top: 2rem !important;
        @include media-breakpoint-up(lg) {
            margin-top: 4rem !important;
        }
        display: flex !important;
        justify-content: center !important;
        .btn {
            text-transform: none !important;
            padding-left: 3rem !important;
            padding-right: 3rem !important;
        }
    }
}

.section-partnership {
    padding: 2.5rem 0 !important;
    text-align: center !important;
    .partnership-logos {
        display: flex !important;
        flex-wrap: wrap !important;
        align-items: center !important;
        justify-content: center !important;
        gap: 1.5rem !important;

        img {
			@extend .img-fluid;
            max-width: 45% !important;
            @include media-breakpoint-up(md) {
                max-width: 30% !important;
            }
            @include media-breakpoint-up(xl) {
                max-width: 15% !important;
            }
        }

    }
}

.news-grid {

    background-color: $light !important;
    padding: 2rem 0 !important;
    h2 {
        margin-bottom: 0 !important;
        @extend .h1;
        text-align: center !important;
    }
    p {
        color: $dark !important;
        font-size: 1.25rem !important;
        text-align: center !important;
    }
    @include media-breakpoint-up(lg) {
        padding: 4rem 0 !important;
        h2, p {
            text-align: left !important;
        }
    }
    .row {
        margin-top: 1rem !important;

        @include media-breakpoint-up(lg) {
            margin-top: 2rem !important;
        }
    }
}

.filter-results {
    background-color: $light !important;
    @extend .col-xl-10;
    @extend .mx-auto;
    display: flex !important;
    flex-direction: column !important;
    padding: 2rem 0 !important;
    gap: 2rem !important;
    @include media-breakpoint-up(lg) {
        padding: 4rem 0 !important;
        gap: 4rem !important;
    }

    .results-header {
        display: flex !important;
        flex-direction: column-reverse !important;
        gap: 2rem !important;

        .header-info {
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: space-between !important;
            gap: 1.5rem !important;
            font-size: 1.125rem !important;
            color: $primary !important;
            a {

                color: $primary !important;
            }
            .info-results {
                width: 100% !important;
                text-align: center !important;
            }
            .info-view, .info-display {
                display: none !important;
            }
        }

        .header-cta {
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            gap: 1.25rem !important;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row !important;
            align-items: center !important;
            justify-content: space-between !important;


            .header-info {
                justify-content: start !important;
                align-items: stretch !important;
                .info-results {
                    width: auto !important;
                }
                .info-view, .info-display {
                    display: block !important;
                }
            }

            .header-cta {
                flex-direction: row !important;
            }
        }
    }

    .results-actions {
        display: flex !important;
        justify-content: space-between !important;
        font-size: 1.125rem !important;
        margin-bottom: 1.5rem !important;
        .action-view {
            @include media-breakpoint-up(lg) {
                display: none !important;
            }
        }

        .action-compare {
            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }
        .action-watch {
            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }
    }

    .results-list {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.5rem !important;
    }

    .result-item {
        display: flex !important;
        flex-direction: column !important;
        background-color: white !important;

        @include media-breakpoint-up(lg) {
            flex-direction: row !important;
        }

        .item-checkbox {
            background-color: #CCC !important;
            padding: 1.25rem !important;
            line-height: 1 !important;
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            flex-shrink: 0 !important;
            @include media-breakpoint-up(lg) {
                padding: 1.875rem !important;
                align-items: center !important;
                .action-compare {
                    display: none !important;
                }
            }
            input[type="checkbox"] {
                appearance: none !important;
                width: 1.25rem !important;
                height: 1.25rem !important;
                border: 1px solid $primary !important;
                background-color: white !important;
                cursor: pointer !important;
                &:checked {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M447.9 142.5l-23.2 22L181 395.3l-22 20.8-22-20.8L23.2 287.6 0 265.6l44-46.5 23.2 22L159 328 380.7 118l23.2-22 44 46.5z' fill='%23041db5'/%3E%3C/svg%3E") !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: 1rem !important;
                }
            }
        }


        .item-img {
            @include media-breakpoint-up(lg) {
                width: 300px !important;
                flex-shrink: 0 !important;
            }
            img {
                width: 100% !important;
                height: 100% !important;
                object-fit: cover !important;
                -o-object-fit: cover !important;
                object-position: center !important;
                -o-object-position: center !important;
            }
        }

        .item-grade {
            flex-shrink: 0 !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            padding: 1rem 0 !important;

            .grade-img-square {
                display: none !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
            }

            .grade-img-horizontal {
                height: 18px !important;
                width: 100% !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 !important;
                align-items: start !important;

                .grade-img-square {
                    width: 80px !important;
                    height: 80px !important;
                    display: block !important;
                }
                .grade-img-horizontal {
                    display: none !important;
                }
            }
        }

        .item-body {
            padding: 1.25rem !important;
            color: $dark !important;
            font-size: 1.125rem !important;
            display: flex !important;
            gap: 1.25rem !important;
            flex-direction: column !important;
            flex-grow: 1;
            
            .body-content {
            	flex-grow: 1;
            }


            @include media-breakpoint-up(xl) {
                flex-direction: row !important;
            }

            h2 {
                color: $dark !important;
            }

            ul {
                @extend .list-unstyled;
                margin-bottom: 0 !important;
            }

            .body-cta {
                flex-shrink: 0 !important;
                display: flex !important;
                align-items: end !important;
                justify-content: center !important;
                .btn-secondary {
                    color: $dark !important;
                }
                @include media-breakpoint-up(lg) {
                    justify-content: stretch !important;
                }
            }
        }
    }
}

.auction-single {

    padding-bottom: 2rem !important;
    @include media-breakpoint-up(lg) {
        padding-bottom: 4rem !important;
    }
    .gallery-wrapper {
        display: flex !important;
        flex-direction: column !important;
    }
    .single-grade {
        padding: 1rem 1.25rem !important;
        display: flex !important;
        justify-content: center !important;
        order: 5 !important;

        .grade-img-horizontal {
            height: 18px !important;
            width: 100% !important;
            background-position: left !important;
            background-repeat: no-repeat !important;
        }

        @include media-breakpoint-up(lg) {
            justify-content: start !important;
            padding: 1.125rem 1.25rem !important;
            order: -1 !important;
        }
    }
    .gallery-slider {
        display: flex !important;
        gap: .5rem !important;
        margin: 1.25rem 0 !important;


        @include media-breakpoint-up(md) {
            gap: 1rem !important;
        }

        @include media-breakpoint-up(lg) {
            gap: 1.25rem !important;
        }

        .slider-img {
            aspect-ratio: 4 / 3 !important;
            img {

                width: 100% !important;
                height: 100% !important;
                object-fit: cover !important;
                -o-object-fit: cover !important;
                object-position: center !important;
                -o-object-position: center !important;
            }
        }

        .slider-arrow {
            background-color: #CCCCCC !important;
            border: 0 !important;
            padding: 0 !important;
            flex-shrink: 0 !important;
            width: 1.25rem !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: 6px auto !important;

            @include media-breakpoint-up(md) {
                width: 2rem !important;
                background-size: 8px auto !important;
            }

            @include media-breakpoint-up(lg) {
                width: 2.5rem !important;
                background-size: 10px auto !important;
            }

            &-right {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12' fill='none'%3E%3Cpath d='M6 6L0 12L-5.24537e-07 0L6 6Z' fill='%23fff'/%3E%3C/svg%3E") !important;
            }
            &-left {
                background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-4.76837e-07 6L6 12L6 0L-4.76837e-07 6Z' fill='%23fff'/%3E%3C/svg%3E%0A") !important;
            }
        }
    }
    .single-details {
        margin-top: 2rem !important;

        @include media-breakpoint-up(lg) {
            margin-top: 0 !important;
            font-size: 1.125rem !important;
        }
        @include media-breakpoint-up(xl) {
            width: 75% !important;
            margin: 0 auto !important;
            font-size: 1.25rem !important;
        }

        .detail-lot {
            font-weight: 400 !important;
        }

        .detail-list {
            @extend .list-unstyled;
            @include media-breakpoint-up(lg) {
                margin-bottom: 2rem !important;
            }

            .detail-item {
                @extend .d-flex;
                .item-key {
                    @extend .flex-fill;
                    @extend .d-flex;
                    &:after {
                        @extend .flex-grow-1;
                        content: '' !important;
                        border-bottom: 2px dotted $dark !important;
                        margin: 0 .375em .375em .375em
                    }
                }
                .item-value {
                    @extend .text-right;
                }
            }

        }
    }
}

.page-404 {
    .page-main {
        text-align: center !important;
        display: flex !important;
        flex-direction: column;
        align-items: center !important;
        font-size: 1.25rem;
        .title-404 {
            font-size: 100px;
            font-weight: bold;
            color: $primary;
            line-height: 1;
        }
        .btn-secondary {
            color: $dark;
            margin-top: 1rem;
        }
    }
}

.action-compare {
    display: flex !important;
    align-items: center !important;
    &:after {
        content: '' !important;
        width: 1em !important;
        height: 1em !important;
        margin-left: .5rem !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position: center !important;
        display: inline-block !important;
        vertical-align: middle !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M288 320l-96 96 96 96 32 0 0-64 16 0c70.7 0 128-57.3 128-128l0-160 48 0 0-56 0-48 0-56L456 0 408 0 352 0l0 56 0 48 0 56 48 0 0 160c0 35.3-28.7 64-64 64l-16 0 0-64-32 0zM408 56l48 0 0 48-48 0 0-48zM224 0L192 0l0 64-16 0C105.3 64 48 121.3 48 192l0 160L0 352l0 56 0 48 0 56 56 0 48 0 56 0 0-56 0-48 0-56-48 0 0-160c0-35.3 28.7-64 64-64l16 0 0 64 32 0 96-96L224 0zM56 408l48 0 0 48-48 0 0-48z' fill='%23041db5'/%3E%3C/svg%3E") !important;
    }
}
.action-watch {
    display: flex !important;
    align-items: center !important;
    &:after {
        content: '' !important;
        width: 1em !important;
        height: 1em !important;
        margin-left: .5rem !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position: center !important;
        display: inline-block !important;
        vertical-align: middle !important;
  		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 512 512%22%3E%3Cpath fill=%22%23041db5%22 d=%22M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z%22/%3E%3C/svg%3E');
    }
}
.action-sort {
    appearance: none !important;
    display: flex !important;
    align-items: center !important;
    background-color: transparent !important;
    border: 0 !important;
    color: $dark !important;
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M0 64l448 0 0 64L0 128 0 64zM0 224l320 0 0 64L0 288l0-64zM192 384l0 64L0 448l0-64 192 0z' fill='%23041db5'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-size: 1rem !important;
    background-position: right !important;
}

ul.pagination {
    @extend .list-unstyled;
    display: none !important;
    @include media-breakpoint-up(lg) {
        display: flex !important;
    }
    gap: .75rem !important;
    justify-content: center !important;

    .pag-item {
        width: 2.5rem !important;
        height: 2.5rem !important;

        a, span {
            width: 100% !important;
            height: 100% !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            border: 1px solid $primary !important;
            font-size: 1.25rem !important;
            font-weight: 700 !important;
            color: $primary !important;
            background-color: white !important;
        }

        &.active, &:not(.disabled):hover {
            a, span {
                background-color: $primary !important;
                color:white !important;
            }
        }

        &.disabled {
            opacity: .25 !important;
        }
    }

}

.nama-grade-1 {
    background-color: #44A750 !important;
    .grade-img-square {
        background-image: url("../images/grade/nama-1.svg") !important;
    }
    .grade-img-horizontal {
        background-image: url("../images/grade/nama-1-mobile.svg") !important;
    }
}
.nama-grade-2 {
    background-color: #A1C958 !important;
    .grade-img-square {
        background-image: url("../images/grade/nama-2.svg") !important;
    }
    .grade-img-horizontal {
        background-image: url("../images/grade/nama-2-mobile.svg") !important;
    }
}
.nama-grade-3 {
    background-color: #FDCC20 !important;
    .grade-img-square {
        background-image: url("../images/grade/nama-3.svg") !important;
    }
    .grade-img-horizontal {
        background-image: url("../images/grade/nama-3-mobile.svg") !important;
    }
}
.nama-grade-4 {
    background-color: #E87C2F !important;
    .grade-img-square {
        background-image: url("../images/grade/nama-4.svg") !important;
    }
    .grade-img-horizontal {
        background-image: url("../images/grade/nama-4-mobile.svg") !important;
    }
}
.nama-grade-5 {
    background-color: #A24A42 !important;
    .grade-img-square {
        background-image: url("../images/grade/nama-5.svg") !important;
    }
    .grade-img-horizontal {
        background-image: url("../images/grade/nama-5-mobile.svg") !important;
    }
}
.nama-grade-u {
    background-color: #A6A6A6 !important;
    .grade-img-square {
        background-image: url("../images/grade/nama-u.svg") !important;
    }
    .grade-img-horizontal {
        background-image: url("../images/grade/nama-u-mobile.svg") !important;
    }
}
