@import "../bootstrap/carousel";


.carousel-indicators {
    margin-top: 1.25rem;
    position: relative;
    gap: .625rem;
    li {
        width: .875rem;
        height: .875rem;
        margin: 0;
        background-color: $primary;
        border-radius: 999px;
        border:0;
        background-clip: unset;
        opacity: 1;
        &.active {
            background-color: white;
            border: 3px solid $primary;
            width: .5rem;
            height: .5rem;
        }
    }
}
